<template>
    <div class="pc reserva">

        <!-- Spiner -->
        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>
        <!--Fin spiner -->

        <NavbarGuest :translator="translator" :user="user" :booking="booking" />

        <div v-if="bookingContract.host" class="contracte container mt-4">

            <h1 v-cloak class="mb-4">
                <!--<span v-if="user.user_type === 'guest'">
                    <i class="fal fa-signature pr-1"></i>
                </span>-->
                {{ bookingContract.headline }}
            </h1>

            <div class="logo" v-if="user.user_type === 'checkin'">
                <img :src="translator.logo" class="img-fluid" />
            </div>

            <!--<h5 v-cloak>{{ bookingContract.headline }}</h5>-->

            <hr />

            <!-- Anfitrión -->
            <h5 v-cloak>{{ translator.amfitrio }}</h5>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.nom }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.host.host_name }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.adreca }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.host.host_address }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.dip }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.host.host_zip }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.poble }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.host.host_city }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.cif }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.host.host_tin }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.telefon }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.host.host_phone }}
                </div>
            </div>

            <hr />

            <!-- Huésped -->
            <h5 v-cloak>{{ translator.hoste }}</h5>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.nom }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.guest.guest_name }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.adreca }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.guest.guest_address }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.dip }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.guest.guest_zip }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.poble }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.guest.guest_city }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.pais }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.guest.guest_country }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.passaport }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.guest.guest_tin }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.telefon }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.guest.guest_phone }}
                </div>
            </div>

            <hr />

            <!-- Localización -->
            <h5 v-cloak>{{ translator.localitzacio }}</h5>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.propietat }}</div>
                <div class="col-7 dada" v-cloak>
                    <!-- {{ bookingContract.property.property_name }} -->
                    {{ booking.lodging_name }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.adreca }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.property.property_address }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.ciutat }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.property.property_zip }} -
                    {{ bookingContract.property.property_city }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.telefon }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.property.property_phone }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.registre }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.property.property_license }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.capacitat }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.property.property_capacity }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.arribada }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.reservation.arrival_date }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-5 label" v-cloak>{{ translator.sortida }}</div>
                <div class="col-7 dada" v-cloak>
                    {{ bookingContract.reservation.departure_date }}
                </div>
            </div>

            <hr v-if="bookingContract.reservation.total_amount != '0'" />

            <!-- Reserva -->
            <h5 v-cloak v-if="bookingContract.reservation.total_amount != '0'">
                {{ translator.reserva }}
                {{ bookingContract.reservation.reservation_id }}
            </h5>
            <div class="row mb-1" v-if="bookingContract.reservation.total_amount != '0'">
                <div class="col-5 label" v-cloak>{{ translator.quantitat }}</div>
                <div class="col-4 dada" v-cloak>
                    {{ bookingContract.reservation.total_amount }}
                    {{ bookingContract.reservation.currency }}
                </div>
            </div>
            <div class="row mb-1" v-if="bookingContract.reservation.cleaning_fee != '0'">
                <div class="col-5 label" v-cloak>{{ translator.carrecneteja }}</div>
                <div class="col-4 dada" v-cloak>
                    {{ bookingContract.reservation.cleaning_fee }}
                    {{ bookingContract.reservation.currency }}
                </div>
            </div>
            <div class="row mb-1" v-if="bookingContract.reservation.tourist_tax != '0'">
                <div class="col-5 label" v-cloak>{{ translator.taxaturistica }}</div>
                <div class="col-4 dada" v-cloak>
                    {{ bookingContract.reservation.tourist_tax }}
                    {{ bookingContract.reservation.currency }}
                </div>
            </div>
            <div class="row mb-1" v-if="bookingContract.reservation.paid != '0'">
                <div class="col-5 label" v-cloak>{{ translator.pagat }}</div>
                <div class="col-4 dada" v-cloak>
                    {{ bookingContract.reservation.paid }}
                    {{ bookingContract.reservation.currency }}
                </div>
            </div>
            <div class="row mb-1" v-if="bookingContract.reservation.pending_payment != '0'">
                <div class="col-5 label" v-cloak>{{ translator.perpagar }}</div>
                <div class="col-4 dada" v-cloak>
                    {{ bookingContract.reservation.pending_payment }}
                    {{ bookingContract.reservation.currency }}
                </div>
            </div>
            <div class="row mb-1" v-if="bookingContract.reservation.security_deposit != '0'">
                <div class="col-5 label" v-cloak>{{ translator.diposit }}</div>
                <div class="col-4 dada" v-cloak>
                    {{ bookingContract.reservation.security_deposit }}
                    {{ bookingContract.reservation.currency }}
                </div>
            </div>

            <hr />

            <!-- Posem els termes i condicions només si no està buit -->
            <div v-if="bookingContract.reservation.general_conditions != ''">
                <a class="d-flex justify-content-between align-items-center" data-toggle="collapse" href="#collapse"
                    role="button" aria-expanded="false" aria-controls="collapse" @click="
                        arrowContract === true
                            ? (arrowContract = false)
                            : (arrowContract = true)
                        ">
                    <h5 class="mb-0" v-cloak>{{ translator.condicions }}</h5>
                    <i class="fas fa-chevron-up" v-if="arrowContract" style="color: var(--colores-gris-medio);"></i>
                    <i class="fas fa-chevron-down" v-else style="color: var(--colores-gris-medio);"></i>
                </a>
                <div class="collapse" id="collapse">
                    <div class="card card-body my-3 p-3" v-html="bookingContract.reservation.general_conditions"
                        v-cloak></div>
                </div>
                <hr />
            </div>

            <h5 v-cloak>{{ translator.signaturaestabliment }}</h5>
            <img :src="bookingContract.host.host_signature" class="img-fluid" />

            <hr />

            <h5 v-cloak>{{ translator.signaturaclient }}</h5>

            <img v-if="guestSignature != ''" :src="guestSignature" class="img-fluid" />



            <div v-if="guestSignature == '' &&

                !(booking.customer_address != '' &&
                    booking.customer_city != '' &&
                    booking.customer_country != '' &&
                    booking.customer_email != '' &&
                    booking.customer_first_name != '' &&
                    booking.customer_last_name != '' &&
                    booking.customer_phone != '' &&
                    booking.customer_tin != '' &&
                    booking.customer_zip != '' &&
                    booking.customer_nationality != '') ||

                (!huespedesCompleto()) ||

                (booking.mandatory_payment_contract === 'true' && (!booking.paymentCompleted
                    // || !((booking.mandatory_deposit_guarantee_at_check_in === 'true' && bookingPayments.deposit_paid === 'true') ||
                    //         (booking.mandatory_deposit_guarantee_at_check_in === 'false') ||
                    //         parseInt(bookingPayments.security_deposit) == 0)
                ))
            " class="mt-3">

                <div class="alert alert-danger" role="alert">
                    {{ translator.faltenregistrescontracte }}:
                    <div class="d-flex flex-column mt-2">
                        <span class="font-weight-bold" v-if="booking.customer_first_name == '' ||
                            booking.customer_last_name == '' ||
                            booking.customer_email == '' ||
                            booking.customer_phone == '' ||
                            booking.customer_address == '' ||
                            booking.customer_city == '' ||
                            booking.customer_zip == '' ||
                            booking.customer_country == '' ||
                            booking.customer_tin == '' ||
                            booking.customer_nationality == ''
                        ">
                            {{ translator.dadespersonals }}
                        </span>
                        <span class="font-weight-bold" v-if="!huespedesCompleto()">
                            {{ translator.hostes }}
                        </span>

                        <span class="font-weight-bold" v-if="booking.mandatory_payment_contract == 'true' && (booking.mandatory_payment_contract == 'true' && !booking.paymentCompleted || !(bookingPayments.deposit_paid == 'true' || bookingPayments.security_deposit == '0.00' || booking.mandatory_deposit_guarantee_at_check_in == 'false'))
                        ">
                            {{ translator.pagament }}
                        </span>
                    </div>
                </div>

                <button class="btn btn-secondary" disabled v-cloak>
                    {{ translator.afegirsignatura }}
                </button>

            </div>
            <div v-else>
                <a v-if="guestSignature == ''" class="btn btn-secondary mt-3" data-toggle="modal" data-target="#Modal"
                    @click="initCanvas" v-cloak>
                    {{ translator.afegirsignatura }}
                </a>
            </div>

            <!-- Modal firma -->
            <div class="modal fade" id="Modal" tabindex="-1" role="dialog" aria-labelledby="ModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="ModalLabel" v-cloak>
                                {{ translator.signar }}
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                @click="clearCanvas()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-center">
                            <canvas id="sketchpad" height="200" width="300" style="border: 1px solid #ccc"></canvas>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-secondary" @click="clearCanvas()" v-cloak>
                                {{ translator.esborrar }}
                            </button>
                            <button type="button" class="btn btn-secondary" :class="{ 'disabled': isCanvasClear }"
                                @click="saveCanvas" v-cloak>
                                {{ translator.guardar }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Fin modal firma -->
        </div>

        <!-- Navegaciones footer -->
        <div class="cotainer d-flex justify-content-between">
            <div>
                <a v-if="isMarcaBlancaActive"
                    :href="`https://app.icnea.net/payment?id=${String(this.$route.query.id).replace(/[ ]/g, '+')}`"
                    class="nav-link"
                    :class="booking.paymentCompleted && (bookingPayments.deposit_paid == 'true' || bookingPayments.security_deposit == '0.00') ? 'todo-ok' : 'falta-info'">
                    <span class="display-responsive"><i class="fa-light fa-chevron-left"></i> {{
                        translator.gestiopagements }}</span>
                </a>
                <router-link v-else
                    :to="{ name: 'GuestPayment', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }"
                    class="nav-link"
                    :class="booking.paymentCompleted && (bookingPayments.deposit_paid == 'true' || bookingPayments.security_deposit == '0.00') ? 'todo-ok' : 'falta-info'">
                    <span class="display-responsive"><i class="fa-light fa-chevron-left"></i> {{
                        translator.gestiopagements }}</span>
                </router-link>
            </div>

            <div v-if="guestSignature != ''">
                <a v-if="isMarcaBlancaActive"
                    :href="`https://${user.user_app}/guest.html?id=${String(this.$route.query.id).replace(/[ ]/g, '+')}`"
                    class="nav-link todo-ok">{{ translator.inici }} <i class="fa-light fa-chevron-right"></i></a>
                <router-link v-else class="nav-link todo-ok"
                    :to="{ name: 'Guest', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }">
                    {{ translator.inici }} <i class="fa-light fa-chevron-right"></i>
                </router-link>
            </div>

        </div>



    </div>
</template>

<script>
var canvas, ctx;
var mouseX,
    mouseY,
    mouseDown = 0;
var touchX, touchY;
var lastX,
    lastY = -1;

import NavbarGuest from "@/components/NavbarGuest.vue";
import { mapState } from "vuex";
const $ = require("jquery");

// Firma android //
let scrollY = '';
$(document).on('show.bs.modal', '#Modal', function () {
    if (navigator.userAgent.match(/Android/i)) {
        const is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        is_firefox ?
            document.documentElement.mozRequestFullScreen() :
            document.documentElement.webkitRequestFullscreen();
    }
    scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    const body = document.body;
    body.style.position = 'fixed';
    body.style.width = '100%';
    body.style.top = `-${scrollY}`;
});
$(document).on('hidden.bs.modal', '#Modal', function () {
    if (navigator.userAgent.match(/Android/i)) {
        const is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        is_firefox ?
            document.mozCancelFullScreen() :
            document.webkitExitFullscreen();
    }
    const body = document.body;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0'));
});
window.addEventListener('scroll', () => {
    document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
});
// //

export default {
    name: "bookingContract",
    components: {
        NavbarGuest,
    },
    computed: {
        ...mapState("utils", ['user', 'translator']),
        ...mapState("guest", ["booking", "bookingContract", "bookingGuests", "bookingPayments", "bookingPayments"]),
    },
    watch: {
        translator() {
            this.$store.dispatch("guest/getHostes", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
            this.$store.dispatch("guest/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
            this.$store.dispatch("guest/getBookingContract", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
            this.$store.dispatch("guest/getBookingPayments", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
        },
        bookingContract() {
            this.guestSignature = this.bookingContract.guest.guest_signature;
        },
        booking() {
            this.checkStatusBooking();
            this.checkAdultsAge();
            this.isPreLoad = false;
            this.huespedesCompleto()
        },
        bookingGuests() {
            let adults = 0;
            let firms = 0;
            this.bookingGuests.forEach(guest => {
                guest._isAdult ? adults++ : '';
                guest._isAdult && guest._isSign ? firms++ : '';
                guest._input_empty ? this.isFilledFields = false : null;
                !guest.document_image ? this.is_allPhotoDocuments = false : null;
                !guest.guest_photo && guest._isAdult ? this.is_allSelfiGuests = false : null;
            });
            firms >= adults ? this.controlAdultWithSign = true : '';
            //firms >= adults && this.booking.adults <= adults ? this.controlAdultWithSign = true : '';
            this.huespedesCompleto()
        },
    },
    data() {
        return {
            isPreLoad: false,
            arrowContract: false,
            guestSignature: "",
            isRegisterDocument: false,
            isAllRegisterAdults: false,
            isAllRegisterChildren: false,
            isAllSignAdults: false,
            isAllSignChildren: false,
            isFilledFields: true,
            is_uploadPhotosAndSelfie: false,
            is_photosDocuments: false,
            is_allAnversoDocument: true,
            is_allSelfiGuests: true,
            // Canvas en blanco
            canvasClear: '',
            isCanvasClear: true,
            isMarcaBlancaActive: false,
            controlAdultWithSign: false,
            is_allPhotoDocuments: true,
        };
    },
    async created() {
        try {
            this.isPreLoad = true;
            (window.location.host == 'app.icnea.net' || window.location.host == 'xapp.icnea.net' || window.location.host == 'localhost:8080') ? this.isMarcaBlancaActive = false : this.isMarcaBlancaActive = true;
            if (this.translator) {
                this.$store.dispatch("guest/getHostes", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
                this.$store.dispatch("guest/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
                this.$store.dispatch("guest/getBookingContract", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
                this.$store.dispatch("guest/getBookingPayments", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
            } else {
                await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            }
        } catch (error) {
            console.error(error);
        } finally {
            //this.isPreLoad = false;
        }
    },
    methods: {
        huespedesCompleto() {
            if (this.booking.mandatory_guests_contract === 'false') {
                return true
            }

            if ((
                (this.booking.mandatory_guests_at_check_in === 'true' && this.booking._registered_adults_only_completed) ||
                this.booking.mandatory_guests_at_check_in === 'false'
            ) &&
                (
                    (this.booking.mandatory_signatures_contract === 'true' && this.controlAdultWithSign) ||
                    this.booking.mandatory_signatures_contract === 'false'
                ) &&
                (
                    (this.booking.mandatory_guests_contract === 'true' && this.isFilledFields && this.booking._registered_adults_only_completed) ||
                    this.booking.mandatory_guests_contract === 'false'
                )
                &&
                (
                    this.booking.mandatory_guests_at_check_in == 'false' ||
                    (
                        (this.booking.mandatory_guests_at_check_in === 'true') &&
                        ((this.booking.visible_selfie === 'true' && this.is_allSelfiGuests) || this.booking.visible_selfie === 'false') &&
                        ((this.booking.visible_passport === 'true' && this.is_allPhotoDocuments) || this.booking.visible_passport === 'false') &&
                        ((this.booking.visible_document === 'true' && this.is_allAnversoDocument) || this.booking.visible_document === 'false')
                    )
                )
            ) {
                return true
            } else {
                return false
            }

        },
        //Mira si el registro esta completado
        checkStatusBooking() {
            this.booking.customer_address &&
                this.booking.customer_city &&
                this.booking.customer_country &&
                this.booking.customer_email &&
                this.booking.customer_first_name &&
                this.booking.customer_last_name &&
                this.booking.customer_phone &&
                this.booking.customer_tin &&
                this.booking.customer_zip &&
                this.booking.customer_nationality
                ? this.isRegisterDocument = true
                : this.isRegisterDocument = false;
            console.log('Reserva rellenada?', this.isRegisterDocument)
        },
        //Mira la edad de los adultos
        checkAdultsAge() {
            let registerAdults = 0,
                registerChildren = 0,
                signAdults = 0,
                signChildren = 0,
                totalSigns = 0,
                is_photosDocuments = true,
                is_uploadAllPhotosAndSelfies = true;
            is_uploadAllPhotosAndSelfies = true;

            this.bookingGuests.forEach(guest => {
                this.booking.mandatory_signatures_contract == 'true' && guest._isSign ? totalSigns++ : null;
                guest._isAdult ? registerAdults++ : registerChildren++;
                guest._isSign && guest._isAdult ? signAdults++ : signChildren++;
                guest._input_empty ? this.isFilledFields = false : null;
                if (guest._isAdult) {
                    guest.is_uploadAllPhotosAndSelfies ? null : is_uploadAllPhotosAndSelfies = false;
                    guest.document_image ? null : is_photosDocuments = false;
                    guest.document_photo ? null : this.is_allAnversoDocument = false;

                    guest.guest_photo && guest._isAdult ? null : this.is_allSelfiGuests = false;
                }

            });
            this.is_uploadAllPhotosAndSelfies = is_uploadAllPhotosAndSelfies
            this.is_photosDocuments = is_photosDocuments
            // Adultos
            parseInt(this.booking.adults) === registerAdults ? this.isAllRegisterAdults = true : '';
            parseInt(this.booking.adults) === signAdults || totalSigns >= parseInt(this.booking.adults) ? this.isAllSignAdults = true : ''; //modo restrictivo quitar || totalSigns >=...
            // Niños
            parseInt(this.booking.children) === registerChildren ? this.isAllRegisterChildren = true : '';
            parseInt(this.booking.children) === signChildren ? this.isAllSignChildren = true : '';

            console.log('total firmas >= al núm. adultos ?', this.isAllSignAdults)
        },

        // Canvas para firma
        initCanvas() {
            canvas = document.getElementById("sketchpad");

            if (canvas.getContext) ctx = canvas.getContext("2d");

            if (ctx) {
                canvas.addEventListener("mousedown", this.sketchpad_mouseDown, false);
                canvas.addEventListener("mousemove", this.sketchpad_mouseMove, false);
                window.addEventListener("mouseup", this.sketchpad_mouseUp, false);

                canvas.addEventListener("touchstart", this.sketchpad_touchStart, false);
                canvas.addEventListener("touchend", this.sketchpad_touchEnd, false);
                canvas.addEventListener("touchmove", this.sketchpad_touchMove, false);
            }
            this.canvasClear = document.getElementById('sketchpad').toDataURL('image/png');
            this.isCanvasClear = true;
        },
        drawLine(ctx, x, y, size) {
            if (lastX == -1) {
                lastX = x;
                lastY = y;
            }

            let r = 0,
                g = 102,
                b = 179,
                a = 255;

            ctx.strokeStyle = "rgba(" + r + "," + g + "," + b + "," + a / 255 + ")";
            ctx.lineCap = "round";
            ctx.beginPath();
            ctx.moveTo(lastX, lastY);
            ctx.lineTo(x, y);
            ctx.lineWidth = size;
            ctx.lineCap = "round";
            ctx.stroke();
            ctx.closePath();
            lastX = x;
            lastY = y;
            this.isCanvasClear = false;
        },
        sketchpad_mouseDown() {
            mouseDown = 1;
            this.drawLine(ctx, mouseX, mouseY, 4);
        },
        sketchpad_mouseUp() {
            mouseDown = 0;
            lastX = -1;
            lastY = -1;
        },
        sketchpad_mouseMove(e) {
            this.getMousePos(e);

            if (mouseDown == 1) {
                this.drawLine(ctx, mouseX, mouseY, 4);
            }
        },
        getMousePos(e) {
            if (!e)
                //var e = event;
                console.log(e); ///No borrar
            if (e.offsetX) {
                mouseX = e.offsetX;
                mouseY = e.offsetY;
            } else if (e.layerX) {
                mouseX = e.layerX;
                mouseY = e.layerY;
            }
        },
        sketchpad_touchStart() {
            this.getTouchPos();
            this.drawLine(ctx, touchX, touchY, 4);
            //event.preventDefault();
        },
        sketchpad_touchEnd() {
            lastX = -1;
            lastY = -1;
        },
        sketchpad_touchMove(e) {
            this.getTouchPos(e);
            this.drawLine(ctx, touchX, touchY, 4);
            //event.preventDefault();
        },
        getTouchPos(e) {
            if (!e)
                //var e = event;
                console.log(e); ///No borrar
            if (e.touches) {
                if (e.touches.length == 1) {
                    var touch = e.touches[0];

                    if (screen.width <= 430) {
                        //Iphone's 6/7/8, Iphones's Plus 6/7/8 y Iphone X
                        touchX = touch.pageX - touch.target.offsetLeft - 10;
                        touchY = touch.clientY - touch.target.offsetTop - 75;
                    } else if (screen.width >= 538 && screen.width <= 569) {
                        //suferce Duo
                        touchX = touch.pageX - touch.target.offsetLeft - 10;
                        touchY = touch.clientY - touch.target.offsetTop - 75;
                    } else if (screen.width > 766 && screen.width <= 800) {
                        // ipad
                        touchX = touch.pageX - touch.target.offsetLeft - 135;
                        touchY = touch.clientY - touch.target.offsetTop - 90;
                    } else if (screen.width > 1022 && screen.width <= 1040) {
                        // ipadPro
                        touchX = touch.pageX - touch.target.offsetLeft - 265;
                        touchY = touch.clientY - touch.target.offsetTop - 90;
                    }
                }
            }
        },
        //Borrar Canvas
        clearCanvas() {
            canvas = document.getElementById("sketchpad");
            if (canvas.getContext) {
                ctx = canvas.getContext("2d");
            }
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            this.canvasClear = document.getElementById('sketchpad').toDataURL('image/png');
            this.isCanvasClear = true;
        },
        //Guardar firmna
        async saveCanvas() {
            this.isPreLoad = true;
            try {
                const guestSign = document.getElementById("sketchpad").toDataURL("image/png");
                if (guestSign != this.canvasClear) {
                    await this.$store.dispatch("guest/postContracteSignatura", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking, jpg: guestSign, ip: "", language: this.user.user_language });
                    this.guestSignature = guestSign;
                    $("#Modal").modal("hide");
                    this.$toast.success(this.translator.guardat);
                    this.clearCanvas();
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.isPreLoad = false;
            }
        },
    }
};
</script>

<style scoped>
.todo-ok {
    color: var(--colores-verde-check) !important;
}

.falta-info {
    color: var(--colores-amarillo-2) !important;
}
</style>