<template>
    <div class="pc reserva">

        <!-- Spiner -->
        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>
        <!--Fin spiner -->

        <NavbarGuest :translator="translator" :user="user" :booking="booking" />

        <!-- Imatge 0 de la propietat -->
        <section v-if="booking._image_format" class="foto-bienvenida" :style="booking._image_format"></section>

        <div class="container mt-4">

            <h1 v-cloak class="mb-4">
                <span>
                    <i class="fal fa-comments pr-1"></i>
                </span>
                {{ translator.testimoni }}
            </h1>

            <section v-if="Object.keys(testimonis).length > 0">
                <div class="mb-3">
                    <label for="titular" class="">{{ translator.titular }}</label>
                    <p>{{ testimonis.content.headline }}</p>
                </div>
                <div class="mb-3">
                    <label for="positiu" class="">{{ translator.testimoni }}</label>
                    <p>{{ testimonis.content.positive }}</p>
                </div>
                <div
                    class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between estrellas">
                    <span>{{ translator.testimoni1 }}:</span>
                    <rate class="comp-estrella" style="display:flex;" :value="testimonis.scoring.location" :length="5"
                        :ratedesc="stringDescription" :disabled="true" />
                </div>
                <div
                    class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between estrellas">
                    <span>{{ translator.testimoni2 }}:</span>
                    <rate class="comp-estrella" style="display:flex;" :value="testimonis.scoring.checkin" :length="5"
                        :ratedesc="stringDescription" :disabled="true" />
                </div>
                <div
                    class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between estrellas">
                    <span>{{ translator.testimoni3 }}:</span>
                    <rate class="comp-estrella" style="display:flex;" :value="testimonis.scoring.cleanliness"
                        :length="5" :ratedesc="stringDescription" :disabled="true" />
                </div>
                <div
                    class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between estrellas">
                    <span>{{ translator.testimoni4 }}:</span>
                    <rate class="comp-estrella" style="display:flex;" :value="testimonis.scoring.comfort" :length="5"
                        :ratedesc="stringDescription" :disabled="true" />
                </div>
                <div
                    class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between estrellas">
                    <span>{{ translator.testimoni5 }}:</span>
                    <rate class="comp-estrella" style="display:flex;" :value="testimonis.scoring.value" :length="5"
                        :ratedesc="stringDescription" :disabled="true" />
                </div>

                <div
                    class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between estrellas">
                    <span>{{ translator.testimoni6 }}:</span>
                    <rate class="comp-estrella" style="display:flex;" :value="testimonis.scoring.accuracy" :length="5"
                        :ratedesc="stringDescription" :disabled="true" />
                </div>
                <div
                    class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between estrellas">
                    <span>{{ translator.testimoni7 }}:</span>
                    <rate class="comp-estrella" style="display:flex;" :value="testimonis.scoring.communication"
                        :length="5" :ratedesc="stringDescription" :disabled="true" />
                </div>
                <div
                    class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between estrellas">
                    <span>{{ translator.testimoni8 }}:</span>
                    <rate class="comp-estrella" style="display:flex;" :value="testimonis.scoring.surroundings"
                        :length="5" :ratedesc="stringDescription" :disabled="true" />
                </div>
                <div
                    class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between estrellas">
                    <span>{{ translator.testimoni9 }}:</span>
                    <rate class="comp-estrella" style="display:flex;" :value="testimonis.scoring.tranquility"
                        :length="5" :ratedesc="stringDescription" :disabled="true" />
                </div>
            </section>
            <section v-else>
                <form>
                    <div class="mb-3">
                        <label for="titular" class="">{{ translator.titular }}</label>
                        <input type="text" class="form-control" v-model="titular">
                    </div>
                    <div class="mb-3">
                        <label for="positiu" class="">{{ translator.testimoni }}</label>
                        <textarea class="form-control" v-model="text_positiu"></textarea>
                    </div>
                    <div
                        class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between estrellas">
                        <span>{{ translator.testimoni1 }}:</span>
                        <rate class=" comp-estrella" style="display:flex;" v-model="preg1" :length="5"
                            :ratedesc="stringDescription" />
                    </div>
                    <div
                        class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between estrellas">
                        <span>{{ translator.testimoni2 }}:</span>
                        <rate class="comp-estrella" style="display:flex;" v-model="preg2" :length="5"
                            :ratedesc="stringDescription" />
                    </div>
                    <div
                        class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between estrellas">
                        <span>{{ translator.testimoni3 }}:</span>
                        <rate class="comp-estrella" style="display:flex;" v-model="preg3" :length="5"
                            :ratedesc="stringDescription" />
                    </div>
                    <div
                        class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between estrellas">
                        <span>{{ translator.testimoni4 }}:</span>
                        <rate class="comp-estrella" style="display:flex;" v-model="preg4" :length="5"
                            :ratedesc="stringDescription" />
                    </div>
                    <div
                        class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between estrellas">
                        <span>{{ translator.testimoni5 }}:</span>
                        <rate class="comp-estrella" style="display:flex;" v-model="preg5" :length="5"
                            :ratedesc="stringDescription" />
                    </div>

                    <div
                        class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between estrellas">
                        <span>{{ translator.testimoni6 }}:</span>
                        <rate class="comp-estrella" style="display:flex;" v-model="preg6" :length="5"
                            :ratedesc="stringDescription" />
                    </div>
                    <div
                        class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between estrellas">
                        <span>{{ translator.testimoni7 }}:</span>
                        <rate class="comp-estrella" style="display:flex;" v-model="preg7" :length="5"
                            :ratedesc="stringDescription" />
                    </div>
                    <div
                        class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between estrellas">
                        <span>{{ translator.testimoni8 }}:</span>
                        <rate class="comp-estrella" style="display:flex;" v-model="preg8" :length="5"
                            :ratedesc="stringDescription" />
                    </div>
                    <div
                        class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between estrellas">
                        <span>{{ translator.testimoni9 }}:</span>
                        <rate class="comp-estrella" style="display:flex;" v-model="preg9" :length="5"
                            :ratedesc="stringDescription" />
                    </div>

                    <button @click.prevent="enviaForm" class="btn btn-primary mt-4">
                        {{ translator.finalitzar }}
                    </button>
                </form>
            </section>
        </div>
    </div>
</template>

<script>
import NavbarGuest from "@/components/NavbarGuest.vue";
import { mapState } from "vuex";

export default {
    name: "GuestReviews",
    components: {
        NavbarGuest
    },

    computed: {
        ...mapState("utils", ['user', 'translator']),
        ...mapState("guest", ['booking', 'testimonis'])
    },

    watch: {
        translator() {
            this.$store.dispatch("guest/getBookingID", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            this.$store.dispatch("guest/getTestimoni", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
        },
        testimonis() {
            this.isPreLoad = false;
        },
    },

    async created() {
        try {
            //this.isPreLoad = true;
            if (this.translator) {
                await this.$store.dispatch("guest/getTestimoni", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
                this.isPreLoad = false;
            } else {
                await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            }
        } catch (error) {
            console.error(error);
        }
    },

    data() {
        return {
            isPreLoad: false,
            stringDescription: ['1', '2', '3', '4', '5'],
            titular: '',
            text_positiu: '',
            text_negatiu: '',
            preg1: 0,
            preg2: 0,
            preg3: 0,
            preg4: 0,
            preg5: 0,

            preg6: 0,
            preg7: 0,
            preg8: 0,
            preg9: 0,
        };
    },
    methods: {
        enviaForm() {
            this.$store.dispatch("guest/postTestimoni", {
                usr: String(this.$route.query.id).replace(/[ ]/g, '+'),
                reserva: this.user.user_booking,
                preg1: this.preg1,
                preg2: this.preg2,
                preg3: this.preg3,
                preg4: this.preg4,
                preg5: this.preg5,
                preg6: this.preg6,
                preg7: this.preg7,
                preg8: this.preg8,
                preg9: this.preg9,
                titular: this.titular,
                texte: this.text_positiu,
                vm: this,
                msn: this.translator.guardat
            })
        }
    }
}
</script>

<style scoped>
form label {
    font-size: 1em;
    margin-bottom: 10px;
}

textarea {
    height: 100px;
}

.comp-estrella>>>button {
    padding: 3px;
}

/* .estrellas {
    margin: px 0;
} */

.estrellas span {
    min-width: 140px;
    padding: 11px 0;
    padding-right: 50px;
}
</style>